<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-end">
      <button @click="$router.push('/clients/add')" class="add">New</button>
    </div>
    <Table
      :items="clients"
      :defaultColumn="0"
      v-on:rowSelected="$router.push('/clients/detail/' + $event._id)"
      css="1fr 150px 80px 1fr 60px"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Person',
          sort: 'alphabet',
          path: ['contactName'],
        },
        {
          name: 'Tel',
          sort: 'numeric',
          path: ['contactPhone'],
        },
        {
          name: 'Email',
          sort: 'alphabet',
          path: ['contactEmail'],
        },
        {
          name: 'Projects',
          sort: 'numeric',
          path: ['projects'],
          formatType: 'count',
        },
      ]"
    >
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      clients: [],
    };
  },
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getClients"]),
    refresh() {
      this.getClients()
        .then((clients) => {
          this.clients = clients.sort((a, b) => {
            return a.name
              .toLowerCase()
              .localeCompare(b.name.toLowerCase(), "cs");
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.users-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}
</style>