var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/clients/add')}}},[_vm._v("New")])]),_c('Table',{attrs:{"items":_vm.clients,"defaultColumn":0,"css":"1fr 150px 80px 1fr 60px","columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['name'],
      },
      {
        name: 'Person',
        sort: 'alphabet',
        path: ['contactName'],
      },
      {
        name: 'Tel',
        sort: 'numeric',
        path: ['contactPhone'],
      },
      {
        name: 'Email',
        sort: 'alphabet',
        path: ['contactEmail'],
      },
      {
        name: 'Projects',
        sort: 'numeric',
        path: ['projects'],
        formatType: 'count',
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/clients/detail/' + $event._id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }